import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import g from 'glamorous'
import { css } from 'glamor'

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: {
            frontmatter: {
              type: { eq: "projects" }
              cat: { eq: "hospitality" }
            }
          }
          sort: { fields: frontmatter___poids, order: ASC }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                cat
                title
                location
                sqft
                building
                service
                type
                poids
                image {
                  childImageSharp {
                    fluid(maxWidth: 516) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Wrapper>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <Item key={node.id} {...cssLink} className="projects-item">
            <Link to={node.fields.slug}>
              <Img
                fluid={node.frontmatter.image.childImageSharp.fluid}
                css={{
                  position: `relative`,
                }}
              />

              <Box className="box">
                <H2>{node.frontmatter.title}</H2>
                <Hr />
                <Location>{node.frontmatter.location}</Location>
              </Box>
            </Link>
          </Item>
        ))}
      </Wrapper>
    )}
  />
)

const Wrapper = g.div({
  marginTop: `35px`,
  paddingBottom: `80px`,
  marginBottom: `180px`,
  display: `grid`,
  gridTemplateColumns: `48% 48%`,
  gridGap: `4%`,
  rowGap: `3%`,
  '@media only screen and (max-width : 680px)': {
    display: `block`,
    padding: `0 15px`,
    marginTop: `15px`,
  },
})

const Item = g.div({
  position: `relative`,
  '@media only screen and (max-width : 680px)': {
    marginBottom: `20px`,
  },
})

const Box = g.div({
  position: `absolute`,
  top: `0`,
  left: `0`,
  backgroundColor: `white`,
  width: `100%`,
  height: `100%`,
  color: `black`,
  borderRadius: `2px`,
  '@media only screen and (max-width : 680px)': {
    display: `none`,
  },
})

const H2 = g.h2({
  textAlign: `center`,
  fontSize: `1.8em`,
  marginTop: `4.2em`,
  marginBottom: `.5em`,
  letterSpacing: `.1em`,
})

const Hr = g.hr({
  width: `18%`,
})

const Location = g.div({
  textAlign: `center`,
  textTransform: `uppercase`,
  fontSize: `.93em`,
  letterSpacing: `.03em`,
  opacity: `.93`,
})

const cssLink = css({
  textDecoration: `none`,
  '& .box': { opacity: '0', transition: `all 0.2s ease-in-out 0s` },
  transition: `all 0.2s ease-in-out 0s`,
  ':hover': {
    transition: `all 0.2s ease-in-out 0s`,
    opacity: '.8',
    filter: `grayscale(85%)`,
    '& .box': { opacity: '0.75', transition: `all 0.2s ease-in-out 0s` },
  },
})
