import React from 'react'
import Layout from '../../components/Layout'
import { Link } from 'gatsby'
import HospitalityProjects from '../../components/HospitalityProjects'
import { css } from 'glamor'
import g from 'glamorous'
import { Helmet } from 'react-helmet'

export default () => {
  return (
    <Layout>
      <Helmet>
        <title>
          Hospitality Projects | Valerie Pasquiou Interiors & Design
        </title>
        <meta
          name="description"
          content="Discover our hospitality and restaurant projects of interior design and interior archiotecture in New York City, Paris and Los Angeles."
        />
      </Helmet>
      <Wrapper>
        <H1>Projects</H1>
        <ButtonWrapper>
          <Link to="/projects/" {...cssLink}>
            Residential
          </Link>
          <Link to="/projects/commercial/" {...cssLink}>
            Commercial
          </Link>
          <Link to="/projects/hospitality/" {...cssLinkCurrent}>
            Hospitality
          </Link>
        </ButtonWrapper>

        <HospitalityProjects />
      </Wrapper>
    </Layout>
  )
}

const H1 = g.h1({
  '@media(min-width: 768px)': {
    display: `none !important`,
  },
  fontSize: `2.5em`,
  paddingBottom: `.3em`,
  display: `block`,
  marginBottom: `0`,
  padding: `0 15px`,
  fontSize: `2em`,
  marginTop: `0`,
  marginBottom: `5px`,
})

const Wrapper = g.div({
  marginTop: `50px`,
  '@media(max-width: 768px)': {
    marginTop: `0px`,
  },
})

const ButtonWrapper = g.div({
  textAlign: `center`,
  paddingTop: `50px`,
})

const cssLink = css({
  display: `inline-block`,
  fontSize: `.9em`,
  letterSpacing: `0.035em`,
  border: `none`,
  textTransform: `uppercase`,
  margin: `0 1.4em`,
  outline: `none`,
  transition: `all .25s`,
  padding: 0,
  textDecoration: `none`,
  color: `#333`,
  opacity: `.5`,
  '@media(max-width: 768px)': {
    display: `block`,
    paddingBottom: `2px`,
  },
})

const cssLinkCurrent = css({
  display: `inline-block`,
  fontSize: `.9em`,
  letterSpacing: `0.035em`,
  border: `none`,
  textTransform: `uppercase`,
  margin: `0 1.4em`,
  outline: `none`,
  transition: `all .25s`,
  padding: 0,
  textDecoration: `none`,
  color: `#333`,
  '@media(max-width: 768px)': {
    display: `block`,
    paddingBottom: `2px`,
  },
})
